(function($){

    var _window;

    var Moringa = {

        $ : null,
        $main : null,
        $header  : null,
        $menu : null,
        $menuToggle : null,
        lastScrolTop : false,
        timeResize : false,
        currentMouseY : -1,
        lastGadget : false,
        bgMenu : null,
        whell : {
            lock : false,
            direction : false,
            counter : 0,
            initTime : 0,
            timeout : false
        },

        init : function(){

            _window = $( window );


            // Body
            this.$ = $( 'body' );

            // Header
            this.$header = this.$.children( 'header' ).first();
            // Menu toggle
            this.$menuToggle = $( '.menu-toggle', this.$header );
            // Menu
            this.$menu = $( 'nav', this.$header );

            // Header
            this.bindHeader();

            // Carrossel projetos mobile
            this.bindProjetosMobile();

            // Slick
            this.bindSlicks();

            // Equipe
            this.bindUnities();

            // Footer
            this.bindFooter();

            // this back projeto
            this.bindBackProjetos();

            // Splash page
            this.bindSplash();

            // Bind arrow
            this.bindArrow();

            // Mobile heights
            if( Utils.isMobile() ){
                Moringa.mobileHeights();
            }

        },

        bindSplash : function(){

            // Elements
            const 
                splash = document.querySelector( 'section.splash' ),
                close = splash.querySelector( 'button.close' ),
                cta = splash.querySelector( 'a.button' ),
                cookie = Cookie.get( 'splash-closed' ),
                views = ( cookie === '' ) ? 0 : parseInt( cookie );
            ;

            // Not exists
            if( splash === null || views >= 2 ) return true;

            Cookie.set( 'splash-closed', ( views + 1 ) );

            // Show
            splash.classList.add( 'showing' )
            Moringa.lockScroll()

            // Click out
            splash.addEventListener( 'click', function( event ){
                if( event.target.classList.contains( 'splash' ) ){
                    Moringa.closeSplash( splash, views );
                }
            })

            // Close button
            close.addEventListener( 'click', function(){
                Moringa.closeSplash( splash, views );
            })
            
            // Call to action button
            cta.addEventListener( 'click', async function( event ){
                await Moringa.closeSplash( splash, views )
            })

        },

        closeSplash : function( splash, views ){
            splash.classList.remove( 'showing' );
            Moringa.unlockScroll();
        },

        bindArrow : function(){

            const arrow = $( 'main.quem-faz-a-moringa > section:first > .seta' );
            if( arrow.length ){
                const main = $( 'main.quem-faz-a-moringa' );
                arrow.on( 'click', () => {
                    main.get(0).scrollTo( 0, window.innerHeight );
                });
            }

        },

        bindProjetosMobile : function(){

            const $lines = $( '.linha-projetos' );
            if( $lines.length && window.innerWidth <= 640 ){

                const 
                    $newSection = $( '<section data-id="projetos" class="carousel-projetos" title="Projetos"><span></span></section>' ).insertBefore( $lines.first() ),
                    $newSectionInner = $newSection.children( 'span' ),
                    $carouselWrapper = $( '<div class="carousel-wrapper"></div>' ).appendTo( $newSectionInner ),
                    $carousel = $( '<div class="carousel"></div>' ).appendTo( $carouselWrapper )
                ;

                // Move items
                $lines.each(function(){
                    this.$ = this.$ || $( this );
                    $( '.projeto', this.$ ).appendTo( $carousel );
                });
                $lines.remove();

                // Start carousel
                const $dots = $( '<div class="dots-nav"></div>').appendTo( $carouselWrapper );
                $carousel.slick({ 
                    dots: true, 
                    appendDots: $dots,
                    arrows: false,
                    infinite: false
                });
                
            }

            // Start navigation
            blockedPage.init();;

        },

        bindBackProjetos : function(){

            const $back = $( 'a[data-action="back-projetos"]' );
            if( $back.length ){

                var beforeURL = this.getCleanURL( document.referrer );
                if( beforeURL.indexOf( '//moringa' ) > -1 ){
                    $back.on( 'click', function(e){
                        e.preventDefault();
                        history.back();
                    });
                }

                $back.css( 'opacity', '1' );

            }

        },

        getCleanURL : function( URL ){
            URL = URL.split( '#' ).shift();
            if( URL.substr( -1 ) === '/' ) URL = URL.substr( 0, URL.length - 1 );
            return URL;
        },

        bindFooter : function(){

            if( window.innerWidth <= 768 ) return true;

            $mails = $( 'a[href^="mailto"]' );
            $mails.on( 'click', function(e){

                e.preventDefault();

                $mails.removeClass( 'copied' );

                this.$ = this.$ || $( this );

                var data = this.$.data( 'copyText' );
                if( data === undefined ){
                    data = {
                        _ : this,
                        $ : this.$,
                        $field = $( '<input type="text" value="' + this.$.text() + '" style="position:fixed;bottom:9999vh;right:9999vw;">' ).appendTo( this.$ ),
                        timout : false
                    };
                    data.field = data.$field.get(0);
                }

                data.field.select();
                data.field.setSelectionRange( 0, 99999 );
                navigator.clipboard.writeText( data.field.value );
                
                data.$.addClass( 'copied' );

                if( data.timeout ) clearTimeout( data.timeout );
                data.timeout = setTimeout( () => {
                    data.$.removeClass( 'copied' );
                }, 2000 );
            
            });

        },

        bindUnities : function(){

            const $unities = $( '.lista-unidades' );
            if( !$unities.length ) return true;

            $( 'button' ).hover(
                function(){
                    this.$li = this.$li || $( this ).parent();
                    this.$li.addClass( 'hover' );
                    $unities.addClass( 'hover' );
                
                },
                function(){
                    this.$li = this.$li || $( this ).parent();
                    this.$li.removeClass( 'hover' );
                    $unities.removeClass( 'hover' );
                
                } 
            );

        },

        // Binding header
        bindHeader : function(){

            // Bind menu toggle
            this.$menuToggle.on( 'click', function(e){
                e.preventDefault();
                Moringa.toggleMenu();
            });

            // Close menu
            $( 'a', this.$menu ).on( 'click', function(){
                Moringa.closeMenu();
            });

        },

        bindSlicks : function(){

            // Equipe
            const $equipe = $( 'section.equipe .slider-wrapper' );
            if( $equipe.length && window.innerWidth <= 640 ){
                    
                const $sliderEquipe = $equipe.children( '.slider' );
                const $dotsEqupe = $( '<div class="dots-nav"></div>').appendTo( $equipe );
                
                $sliderEquipe.slick({
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true,
                    appendDots: $dotsEqupe,
                    dotsClass: 'slick-dots color-black',
                    arrows: false,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 541,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 401,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            
            }

            // Projetos
            const $sldProjetos = $( 'section.projetos .slider-wrapper' );
            if( $sldProjetos.length ){

                $sldProjetos.each(function(){

                    this.$ = this.$ || $( this );
                    
                    const $slider = this.$.children( '.slider' );

                    // Desktop
                    if( window.innerWidth > 768 ){

                        const 
                            $arrows = $( '<menu class="arrows-nav"></menu>' ).appendTo( this.$ ),
                            $arrowPrev = $( '<button class="prev" title="Anterior"><svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><g><path d="M33.332 18.3334V21.6667H13.332L22.4987 30.8334L20.132 33.2L6.93203 20L20.132 6.80005L22.4987 9.16672L13.332 18.3334H33.332Z" fill="#F2F2F2"/></g></svg></button>' ).appendTo( $arrows ),
                            $arrowNext = $( '<button class="next" title="Próximo"><svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><g><path d="M33.332 18.3334V21.6667H13.332L22.4987 30.8334L20.132 33.2L6.93203 20L20.132 6.80005L22.4987 9.16672L13.332 18.3334H33.332Z" fill="#F2F2F2"/></g></svg></button>' ).appendTo( $arrows )
                        ;
                        $slider.slick({ 
                            dots: false, 
                            arrows: true,
                            fade: true,
                            infinite: false,
                            appendArrows: $arrows,
                            prevArrow: $arrowPrev,
                            nextArrow: $arrowNext
                        });

                    }
                    // Mobile
                    else {

                        $( '.projeto', $slider ).each(function(){
                            this.$ = this.$ || $( this );
                            $( '<div class="dots-nav"></div>').prependTo( this.$.find( 'footer' ) );
                        });
                        const $dots = $( '.dots-nav', $slider );

                        $slider.on( 'afterChange', function( event, slick, currentSlide ) {
                            $.each( slick.$dots, ( i, el ) => {
                                $( el ).find( 'li' ).eq( currentSlide ).addClass( 'slick-active' );
                            });
                        });
                        $slider.slick({ 
                            dots: true, 
                            appendDots: $dots,
                            arrows: false,
                            fade: true,
                            infinite: false
                        });
                    
                    }
                
                });            

            }

        },
        
        lockScroll: function(){
            blockedPage.locked = true;
        },

        unlockScroll: function(){
            blockedPage.locked = false;
        },

        getScroll : function() {
        
            if( window.pageYOffset != undefined ){
                return { left: pageXOffset, top: pageYOffset };
            } 
            else {
                
                var sx, sy, d = document,
                    r = d.documentElement,
                    b = d.body
                ;
                sx = r.scrollLeft || b.scrollLeft || 0;
                sy = r.scrollTop || b.scrollTop || 0;
            
                return { left: sx, top: sy };
            
            }
        
        },

        mobileHeights : function(){
            var vHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },

        vewportWidth : function(){
            var vHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },

        scrollHeight : function(){
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( this.$.hasClass( 'menu-opened' ) ){
                this.closeMenu();
            }
            else {
                this.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            this.$menuToggle.addClass( 'opened' );
            this.$.addClass( 'menu-opened' );
        },

        // Close menu mobile
        closeMenu : function(){
            this.$menuToggle.removeClass( 'opened' );
            this.$.removeClass( 'menu-opened' );
        }

    };
    $(function(){       
        Moringa.init();
    });

})(jQuery);
